import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Button,
  Card,
  WixDesignSystemProvider,
  MarketingPageLayout,
  MarketingPageLayoutContent,
  Page,
  Loader,
  CustomModalLayout,
  Modal,
  Notification,
  Cell
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [InteractiveDemoOpen, setInteractiveDemoOpen] =
  React.useState(false);
  const isFree = instanceData?.instance?.isFree !== false;

  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/spotify/_functions", // Replace with your API base URL
      applicationID: "02e25acd-59db-49a7-bb3a-5acb1097ff66", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, (value: boolean) => {
      setIsUpgraded(value);
      setIsUpgradeBannerOpen(!value);
    });
  }, []);

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const upgradeUrl = basePlatform.getUpgradeUrl();
  const openInteractiveDemo = () => {
    setInteractiveDemoOpen(true);
  };
  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Modal
            isOpen={InteractiveDemoOpen}
            screen="desktop"
            shouldDisplayCloseButton={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setInteractiveDemoOpen(false)}
          >
             <CustomModalLayout
              removeContentPadding={true}
              content={
                <div style={{ position: "relative", paddingBottom: "calc(56.22% + 41px)", height: 0, width: "100%" }}>
                  <iframe
                    src="https://app.arcade.software/share/yhiSDl2NqBwv3kwZPF2O?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true"
                    title="Get Paid with Spotify Music Player | Getting Started"
                    frameBorder="0"
                    loading="lazy"
                    allow="clipboard-write"
                    allowFullScreen
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }}
                  ></iframe>
                </div>
              }
            />
          </Modal>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
          {`Upgrade to unlock "Access to Velo API for advanced customization"`}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          Upgrade
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="440px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Spotify Music Player"
                content="To use this widget, go to Editor > Add Panel (+) > App Widgets > Spotify Music Player"
                actions={
                  <Box gap="SP2">
                    <Button
                      suffixIcon={<Icons.Edit />}
                      as="a"
                      href={`https://www.wix.com/editor/${instanceData?.site?.siteId}`}
                      target="_blank"
                    >
                      Edit Site
                    </Button>
                    <Button
                      suffixIcon={<Icons.PremiumFilled />}
                      skin={isUpgraded ? "premium-light" : "premium"}
                      as="a"
                      // href={upgradeUrl}
                      target="_blank"
                    >
                      {isUpgraded ? "Manage Plan" : "Upgrade App"}
                    </Button>
                  </Box>
                }
              />
            </Box>
          }
        />
      </Card>
      <Cell span={12}> 
            <Card>
              <MarketingPageLayout
                removeImageHorizontalPadding
                removeImageVerticalPadding
                content={
                  <Box  marginTop= "-30px" verticalAlign="middle">
                    <MarketingPageLayoutContent
                      size="medium"
                      title="Learn how to setup Spotify Music Player"
                      content="Estimated time: 5 minutes"
                      actions={
                        <Box marginTop="-30px">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={openInteractiveDemo}
                        >
                          Watch video
                        </Button>
                      </Box>
                      }
                    />
                  </Box>
                }
              />
            </Card>
      </Cell>


    </WixDesignSystemProvider>
  );
}

export default App;
